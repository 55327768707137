.multi-step-container {
  width: 95%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #a52444;
  text-align: center;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.step {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
}

.step-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bbb;
  border-radius: 50%;
  font-weight: bold;
}

.step.active .step-number {
  border-color: black;
  color: #efb423;
}

.step.active {
  color: #efb423;
}

.step.completed .step-number {
  border-color: black;
  color: black;
}

.step.completed {
  color: black;
}

.step-line {
  flex-grow: 1;
  height: 2px;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.step-content {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 1.2rem; */
  /* font-weight: bold; */
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  padding: 20px;
  background-color: white;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.prev-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
}

.prev-btn:disabled {
  background-color: #a52444;
  cursor: not-allowed;
}

.next-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
}

.next-btn:disabled {
  background-color: #a52444;
  cursor: not-allowed;
}
