.multi-step-container {
  width: 95%;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #a52444;
  text-align: center;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.step {
  display: flex;
  align-items: center;
  gap: 8px;
  /* font-weight: bold; */
  color: #ffffff;
  position: relative;
}

.step-number {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bbb;
  border-radius: 50%;
}

.step.active .step-number {
  border-color: rgb(0, 0, 0);
  color: #efb423;
}

.step.active {
  color: #efb423;
}

.step.completed .step-number {
  border-color: rgb(255, 0, 0);
  color: black;
}

.step.completed {
  color: rgb(255, 0, 0);
}

.step-line {
  flex-grow: 1;
  height: 2px;
  background-color: #ddd;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

/* .step-content {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  background: #f1f6f7;
} */

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.prev-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
}

.prev-btn:disabled {
  background-color: #a52444;
  cursor: not-allowed;
}

.next-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
}

.next-btn:disabled {
  background-color: #a52444;
  cursor: not-allowed;
}

.pandit-card {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.pandit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.pandit-card.selected {
  border: 2px solid #efb423;
}

.card-img-container {
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-img-top {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.card-title {
  font-size: 18px;
  font-weight: 500;
  color: #343a40;
}

.card-text {
  font-size: 14px;
  margin-bottom: 5px;
}

/* Star Rating */
.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: #ffc107;
  margin-top: 5px;
}

.filled-star {
  color: #ffc107; /* Yellow for full stars */
}

.half-star {
  color: #ffc107; /* Yellow for half stars */
}

.empty-star {
  color: #ddd; /* Grey for empty stars */
}

.rating-text {
  font-size: 14px;
  margin-left: 5px;
  color: #343a40;
  font-weight: bold;
}

/* .booking-details-container {
  width: 90vw;
  display: flex;
  gap: "20px";
  justify-content: space-between;
  align-items: flex-start;
}

.booking-details-left {
  flex: 2;
  margin-right: 20px;
}

.booking-details-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.booking-form-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.booking-form-container h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  border-bottom: 3px solid #28a745;
  padding-bottom: 5px;
  display: inline-block;
}

/* .booking-details-left h6 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #143642;
} */

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.input-field:focus {
  border-color: #28a745;
  outline: none;
}

.error-text {
  font-size: 0.9rem;
  color: red;
  margin-top: 5px;
}

.price-summary {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.price-summary h4 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #143642;
}

.price-summary ul {
  list-style: none;
  padding: 0;
}

.price-summary li {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  font-size: 1rem;
  /* border-bottom: 1px solid #eee; */
}

.total-price {
  font-size: 1.3rem;
  font-weight: bold;
  color: #28a745;
}

.coupon-container {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.coupon-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.apply-btn {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.place-order-btn {
  width: 100%;
  background: #28a745;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
}

.booking-details-left {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.booking-preview-details-puja {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.booking-preview-details-puja-top {
  display: flex;
  gap: "20px";
}

.booking-preview-details-puja-top-left {
  width: 5rem;
  margin-right: 6rem;
  /* display: flex;
  align-items: center; */
  /* justify-content: center; */
}

.booking-preview-details-puja-top-left > img {
  width: 8rem;
  border-radius: 15px;
}

.booking-preview-details-puja-top-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.booking-preview-details-puja-top-right > .puja-name {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
