/* style switcher */
.style-switcher {
  font-family: var(--insur-font);
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 99999;
  background-color: #fff;
  width: 200px;
  text-align: center;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  border-top-right-radius: var(--insur-bdr-radius, 10px);
  border-bottom-right-radius: var(--insur-bdr-radius, 10px);
}

.style-switcher.active {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.style-switcher h3 {
  margin: 0;
  text-transform: uppercase;
  color: var(--insur-black);
  font-size: 20px;
  letter-spacing: var(--insur-letter-spacing);
  line-height: 1;
  font-family: var(--insur-font);
  margin-bottom: 15px;
  font-weight: 800;
}

.style-switcher .layout-feature,
.style-switcher .language-feature {
  display: -ms-grid;
  display: grid;
  grid-gap: 10px;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  margin-top: 15px;
}

.style-switcher .layout-feature button,
.style-switcher .layout-feature a,
.style-switcher .language-feature button {
  border: none;
  outline: none;
  font-family: var(--insur-font);
  text-transform: uppercase;
  background-color: var(--insur-black);
  color: #fff;
  letter-spacing: var(--insur-letter-spacing);
  font-size: 12px;
  font-weight: 700;
  line-height: 36px;
  border-radius: var(--insur-bdr-radius);
}

.style-switcher .layout-feature a:nth-child(odd),
.style-switcher .layout-feature button:nth-child(odd),
.style-switcher .language-feature button.rtl-switcher {
  background-color: var(--insur-base);
  color: #fff;
}

.style-switcher .layout-feature button:last-child {
  -ms-grid-column: 1;
      grid-column-start: 1;
  grid-column-end: 3;
}

#switcher-toggler {
  position: absolute;
  top: 20px;
  left: 100%;
  font-size: 22px;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: var(--insur-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 500ms;
  transition: 500ms;
  border-top-right-radius: var(--insur-bdr-radius, 10px);
  border-bottom-right-radius: var(--insur-bdr-radius, 10px);
}

#switcher-toggler:hover {
  color: #fff;
  background-color: var(--insur-black);
}

#styleOptions {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  grid-gap: 15px;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

#styleOptions li {
  width: 100%;
  height: 46.6667px;
  border-radius: 50%;
  overflow: hidden;
}

#styleOptions li a {
  display: block;
  width: 100%;
  height: 100%;
}

/* lang bar  */
.skiptranslate {
  display: none !important;
}

/* body fix */
body {
  top: auto !important;
}
/*# sourceMappingURL=nisoz-toolbar.css.map */