/* Main container */
.main {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

/* Left Sidebar */
.left {
  background: #fff;
  /* padding: 15px; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  height: auto;
  max-height: max-content;
}

.left h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: none;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  padding: 10px 15px;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar-menu li:hover,
.sidebar-menu .active {
  /* background: #f2f2f2; */
  color: #a52444;
  font-weight: bold;
}

/* Category Images */
.puja-icon {
  width: 30px;
  height: 30px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
}

/* Right Section */
.right {
  padding-left: 30px;
  flex-grow: 1;
  margin-top: 10px;
}

.searching {
  margin-bottom: 20px;
}

.search-name {
  display: flex;
  align-items: center;
  width: 100%;
}

.input-group {
  width: 100%;
}

.search-btn {
  color: white;
  font-size: 18px;
}

/* Puja List */
h4 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* .row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
} */

/* .box {
  border-radius: 8px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
} */

/* .box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
} */

.figure img {
  width: 100%;
  max-height: 200px;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.details {
  padding: 15px;
  background: white;
  text-align: center;
}

.details h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.details h5 {
  font-size: 16px;
  color: green;
}

.details span {
  /* color: gray; */
  text-decoration: line-through;
  margin-left: 5px;
}

.add {
  margin-top: 10px;
}

.det h6 {
  font-size: 14px;
  color: red;
}

.det h5 {
  font-size: 14px;
  color: green;
  cursor: pointer;
}

/* View More Button */
.view-more {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}
