.journey-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  position: relative;
  width: 100%;
}

.journey-container h3 {
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 60px;
}

.journey-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 40px 0;
  position: relative;
}

.journey-step.reverse {
  flex-direction: row-reverse;
}

.journey-image {
  width: 220px; /* Increased size */
  height: 220px;
  border-radius: 10%;
  object-fit: cover;
  border: 6px solid #00bcd4; /* Slightly thicker border */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for a pop effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.journey-image:hover {
  transform: scale(1.1); /* Slight enlargement on hover */
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

.journey-text {
  max-width: 600px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  margin-left: 20px;
}

.reverse .journey-text {
  margin-left: 0;
  margin-right: 20px;
}

/* Timeline Path */
/* .journey-container::before {
  content: "";
  position: absolute;
  width: 5px;
  background: linear-gradient(180deg, #00bcd4, #0056b3);
  height: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
} */

/* Dots on timeline */
.journey-step::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  border: 5px solid #00bcd4;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
