span.MuiCircularProgress-root.MuiCircularProgress-indeterminate.MuiCircularProgress-colorPrimary.css-18lrjg1-MuiCircularProgress-root {
    position: relative;
    top: 50vh;
  }
  
  svg.MuiCircularProgress-svg.css-1idz92c-MuiCircularProgress-svg {
    color: #00afef !important;
  }
  .css-79ws1d-MuiModal-root {
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
  }
  