
.product-image {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-image img {
    width: 250px;
    height: auto;
    border-radius: 10px;
}
.qty-btn {
    background: #ddd;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
}
.qty-section {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.qty-input {
    width: 40px;
    text-align: center;
    margin: 0 10px;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
}

.product-details {
    margin-left: 20px;
    flex: 1;
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.rating {
    color: green;
    font-weight: bold;
}

.delivery {
    color: blue;
    margin: 5px 0;
}

.size-section h3 {
    margin: 10px 0;
}

.sizes button {
    padding: 8px 12px;
    margin: 5px;
    border: 1px solid #ddd;
    background: white;
    cursor: pointer;
    border-radius: 5px;
}

.sizes button:hover {
    background: #f0f0f0;
}

.product-info {
    margin-top: 20px;
}

.product-info ul {
    list-style: none;
    margin-top: 5px;
}

.actions {
    margin-top: 20px;
}

.actions button {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.cart {
    background: #f3f3f3;
    color: black;
}

.buy {
    background: purple;
    color: white;
}

.cart:hover {
    background: #e0e0e0;
}

.buy:hover {
    background: darkmagenta;
}
