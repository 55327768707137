.pandit-list-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 20px; */
  width: 100%;
}

.pandit-card-wrapper {
  flex: 1 1 calc(33.33% - 20px); /* Default: 3 cards per row */
  max-width: 320px; /* Prevents cards from getting too big */
  min-width: 280px;
}

.pandit-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  border: 1px solid #ddd;
}

.pandit-card:hover {
  transform: translateY(-5px);
}

.figure {
  width: 100%;
  height: 220px; /* Fixed height */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
}

.pandit-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details {
  padding: 15px;
}

.details h4 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
}

.details h5 {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.details h5 strong {
  color: #28a745;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .pandit-card-wrapper {
    flex: 1 1 calc(50% - 20px); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  .pandit-card-wrapper {
    flex: 1 1 calc(100% - 20px); /* 1 card per row */
  }
}
