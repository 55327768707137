.PujaDetails {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #fff;
}

.PujaDetailsContainer {
  display: flex;
  /* max-width: 1100px; */
  width: 100%;
  background-color: #fff;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  border-radius: 10px;
}

.left {
  flex: 1;
  max-width: 40%;
}

.left img {
  width: 100%;
  border-radius: 10px;
}

.right {
  flex: 1.5;
  padding: 20px;
  font-family: "Poppins", sans-serif;
}

h6 {
  font-size: 22px;
  font-weight: bold;
}

h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

h4 {
  font-size: 22px;
  color: #333;
}

h5 {
  font-size: 18px;
  font-weight: bold;
}

span s {
  color: red;
  font-size: 16px;
}

p {
  font-size: 16px;
  line-height: 1.6;
  color: black;
}

.mb-3 {
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 5px;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.button-container {
  text-align: center;
}
