.stepper-wrapper {
  margin-top: auto;
  display: flex
;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-left: 20em;
  padding-right: 20em;
} 
.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid rgb(158, 156, 156);
  width: 100%;
  top: 12px;
  left: 50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid rgb(158, 156, 156);
  width: 100%;
  top: 12px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex
;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  border: 1px solid black;
  border-radius: 50%;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-size: small;
  font-weight: 500;
}

.stepper-item.active .step-counter {
  background-color: #a52444;
  color: white;
}

.stepper-item.completed .step-counter {
  background-color: #a52444;
  color: white;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid rgb(158, 156, 156);
  width: 100%;
  top: 13px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}
.stepper-item:last-child::after {
  content: none;
}

.navigation-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background 0.3s;
}

.prev-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
}

.prev-btn:disabled {
  background-color: #a52444;
  cursor: not-allowed;
}

.next-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 10px;
  font-size: 18px;
}

.next-btn:disabled {
  background-color: #a52444;
  cursor: not-allowed;
}
