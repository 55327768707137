body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: auto !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.main-slider-two__btn {
  display: flex;
  justify-content: flex-start; /* Aligns the button to the left */
  margin-top: 15px; /* Creates space between text and button */
}

.careox-btn {
  display: inline-block; /* Ensures the button width is based on content */
  width: auto; /* Prevents full width */
  padding: 8px 16px; /* Adjust padding as needed */
  text-align: center; /* Centers text inside button */
}

.main-footer {
  /* background: url("../assets/images/backgrounds/footer-bg-1-1.jpg") no-repeat */
  background: url("assets/images/backgrounds/footer-bg-1-1.jpg") no-repeat
    center center;
  background-size: cover;
  position: relative;
  color: white; /* Ensure text is visible */
}

.react-date-picker__wrapper {
  padding: 1px 12px !important;
  border-radius: 8px !important;
  height: 45px;
  border: 1px solid lightgray !important;
  /* z-index: 9999 !important; */
}

.react-calendar {
  z-index: 99999 !important;
  position: absolute !important;
}

.error-msg {
  color: red;
  font-size: 15px;
  font-family: Poppins;
  line-height: 26px;
}

.form-control {
  width: 100%;
  height: 45px;
  border-radius: 6px;
  padding: 0px 20px 2px;
  color: #555555;
  background: #f5f5f5;
  border: 1px solid #e8e8e8;
}
/* Left Table Animation */
.slide-in-left {
  animation: slideLeft 1s ease-out forwards;
}

@keyframes slideLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Right Table Animation */
.slide-in-right {
  animation: slideRight 1s ease-out forwards;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.panchangCard {
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.panchangCard:hover {
  transform: scale(1.03);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #f8f9fa; /* Light gray background on hover */
}

.about-one__text {
  font-size: 24px;
  color: var(--careox-black, #122f2a);
  font-family: var(--careox-special-font, "Caveat", cursive);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px !important;
}
.page-header__title {
  font-size: 45px;
  line-height: 81px;
}
.page-header {
  padding-top: 42px;
  padding-bottom: 34px;
}
.faq-one {
  position: relative;
  padding: 80px 0;
  counter-reset: count;
}
.testimonials-page {
  position: relative;
  padding: 66px 0;
}
.feature-one--about {
  background-color: transparent;
  padding: 66px 0 50px;
  margin-top: 0;
}
.service-one {
  position: relative;
  background-color: var(--careox-gray, #f1f6f7);
  padding: 87px 0 80px;
}
.service-one__item::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 36px);
  border: 1px dashed var(--careox-border-color, #dddddd);
  content: "";
  border-radius: 20px;
  transition: all 500ms ease;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transform: scale(0.9);
}
.about-one__image {
  position: relative;
  z-index: 2;
  padding-left: 0px;
  padding-bottom: 0px;
}
.about-one {
  position: relative;
  padding: 48px 0 48px;
  overflow: hidden;
}
.about-one__content {
  position: relative;
  padding-top: 9px;
}
.about-one__content .sec-title {
  padding-bottom: 3px;
}
.about-one__content__text {
  margin: 0 0 12px;
}
.about-one__content__border {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: var(--careox-border-color, #dddddd);
  margin: 16px 0 9px;
}
.about-one {
  position: relative;
  padding: 48px 0 100px;
  overflow: hidden;
}
.testimonials-page__card__image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--accent-color);
}

.zodiac-card {
  background-color: #f3c37b;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}
.zodiac-card:hover {
  transform: scale(1.05);
}
.zodiac-icon {
  width: 80px;
  height: 80px;
  background-color: #d1773d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 10px;
}
.zodiac-icon img {
  width: 60px;
  height: 60px;
}
.zodiac-name {
  font-size: 18px;
  font-weight: bold;
  color: #4a2908;
}
.zodiac-sign {
  font-size: 14px;
  color: #784c23;
}
.circle-img {
  width: 50px; /* Set width */
  height: 50px; /* Set height */
  border-radius: 50%; /* Makes it circular */
  object-fit: cover; /* Ensures image fits properly */
  border: 2px solid #fff; /* Optional: Adds a white border */
}

.booking-details-container {
  width: 90vw;
  display: flex;
  gap: "20px";
  justify-content: space-between;
  align-items: flex-start;
}

.booking-details-left {
  flex: 2;
  margin-right: 20px;
}

.booking-details-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-content {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  background-color: lightgray;
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  background: #f1f6f7;
}

.custom-search {
  max-width: 20rem;
  border-radius: 8px;
  background: white;
  /* border: 2px solid white; */
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.custom-search-input {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid lightgray;
  outline: none;
  font-size: 15px;
  padding-left: 15px;
}

.custom-search-input:focus {
  border: 1px solid #a52444; /* Blue outline */
}

.product-card {
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
  padding: 15px 8px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.product-card:hover {
  outline: none;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #a52444;
}

.product-content {
  padding-top: 6px;
  /* text-align: center; */
  border-top: 1px solid #cdebd8;
}

.price-summary {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}

.price-summary h4 {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #143642;
}

.price-summary ul {
  list-style: none;
  padding: 0;
}

.price-summary li {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
  font-size: 1rem;
  /* border-bottom: 1px solid #eee; */
}

.card-title {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines before truncation */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

:root {
  --main-color: #a52444;
}
.main-timeline {
  font-family: "Poppins", sans-serif;
}
.main-timeline:after {
  content: "";
  display: block;
  clear: both;
}
.main-timeline .timeline {
  width: 52%;
  margin: 0 0 20px;
  float: left;
  position: relative;
  z-index: 1;
}
.main-timeline .timeline-content {
  color: var(--main-color);
  padding: 0 165px 0 0;
  display: block;
  position: relative;
}
.main-timeline .timeline-content:hover {
  text-decoration: none;
}
.main-timeline .timeline-icon {
  color: var(--main-color);
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 100px;
  height: 100px;
  width: 45px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0;
}
.main-timeline .timeline-year {
  color: #fff;
  background-color: var(--main-color);
  font-size: 20px;
  line-height: 100px;
  height: 100px;
  width: 100px;
  padding: 0 0 0 12px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 50px;
  clip-path: polygon(0 0, 0 100%, 100% 50%);
}
.main-timeline .title {
  font-size: 19px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 10px;
}
.main-timeline .description {
  font-size: 13px;
  letter-spacing: 1px;
  margin: 0;
}
.main-timeline .timeline:nth-child(even) {
  float: right;
  margin: 0 0 20px 10px;
}
.main-timeline .timeline:nth-child(even) .timeline-content {
  padding: 0 0 0 165px;
}
.main-timeline .timeline:nth-child(even) .timeline-icon {
  left: 0;
  right: auto;
}
.main-timeline .timeline:nth-child(even) .timeline-year {
  text-align: right;
  padding: 0 12px 0 0;
  left: 50px;
  right: auto;
  clip-path: polygon(100% 0, 100% 100%, 0 50%);
}
.main-timeline .timeline:nth-child(2) {
  --main-color: #a52444;
}
.main-timeline .timeline:nth-child(3) {
  --main-color: #a52444;
}
.main-timeline .timeline:nth-child(4) {
  --main-color: #a52444;
}
@media screen and (max-width: 767px) {
  .main-timeline .timeline,
  .main-timeline .timeline:nth-child(even) {
    width: 100%;
    margin: 0 0 30px;
  }
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    padding: 0 0 0 165px;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: 0;
    right: auto;
  }
  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year {
    text-align: right;
    padding: 0 12px 0 0;
    left: 50px;
    right: auto;
    clip-path: polygon(100% 0, 100% 100%, 0 50%);
  }
}
@media screen and (max-width: 576px) {
  .main-timeline .timeline-content,
  .main-timeline .timeline:nth-child(even) .timeline-content {
    text-align: center;
    padding: 165px 0 0 0;
  }
  .main-timeline .timeline-icon,
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    line-height: 51px;
    width: 100px;
    height: 50px;
    transform: translateY(0) translateX(-50%);
    top: 0;
    left: 50%;
  }
  .main-timeline .timeline-year,
  .main-timeline .timeline:nth-child(even) .timeline-year {
    text-align: center;
    line-height: 140px;
    width: 100px;
    height: 100px;
    padding: 0;
    transform: translateY(0) translateX(-50%);
    top: 55px;
    left: 50%;
    clip-path: polygon(0 100%, 100% 100%, 50% 0);
  }
}

.card-custom {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensures all cards stretch */
  justify-content: center;
  align-items: stretch;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
}

.card-custom__header {
  width: 100%;
}

.card-custom__header img {
  width: 100%;
  height: 200px; /* Adjust as needed */
  object-fit: cover; /* Ensures images maintain aspect ratio */
}

.card-custom__body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px;
  min-height: 100px;
  flex-grow: 1; /* Expands to fill available space */
  justify-content: space-between; /* Distributes content */
}

.card-custom__body h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.card-custom__body p {
  font-size: 14px;
  margin-top: 10px;
  flex-grow: 1;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 20px;
}

.blog-footer {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding: 15px 0;
  margin-top: auto; /* Ensures it stays at the bottom */
  margin-left: 15px;
}

.blog-footer span {
  display: inline-block;
  background-color: #a52444; /* Primary button color */
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 50px; /* Rounded pill shape */
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.blog-footer span:hover {
  background-color: #a52444; /* Darker shade on hover */
}

.card-shadow-single {
  box-shadow: 0px 0px 5px #0000002e !important;
}

.card-shadow-sty:hover {
  box-shadow: 0px 5px 14px 1px #0000002e !important;
}

.nav-list {
  padding: 0px 10px;
  font-size: 12px;
}

.login-signup-nav {
  font-size: 15px;
  display: flex;
  align-items: center;
  font-family: var(--careox-heading-font, "Poppins", sans-serif);
  color: black;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.4px;
  position: relative;
  transition: all 500ms ease;
}
.detect-location-div {
  cursor: pointer;
  font-size: 15px;
  color: #253b44;
}

/* .btn-custom-pick-city {
  background-color: #a52444;
  color: white;
} */

.tagline-img {
  height: 30px;
}

.custom-modal-width {
  max-width: 400px; /* Adjust width as needed */
}

.how-we-help-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: "5px";
}
.hover-zoom-out {
  transition: transform 0.3s ease-out;
}

.hover-zoom-out:hover {
  transform: scale(1.01);
}
