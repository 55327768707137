/* .about-us {
  background-color: #f9f9f9;
  padding: 60px 0;
} */

.about-content {
  background: #ffffff;
  padding: 30px;
  border-radius: 10px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
  text-align: justify;
  transition: transform 0.3s ease-in-out;
}

/* .about-content:hover {
  transform: translateY(-5px);
} */

.section-title {
  font-size: 30px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  text-transform: none;
  position: relative;
}

.section-title::after {
  content: "";
  display: block;
  width: 80px;
  height: 4px;
  background-color: #d3540000;
  margin-top: 8px;
}

.section-subtitle {
  font-size: 22px;
  font-weight: 600;
  color: #444;
  margin-top: 25px;
}

.about-content p {
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 15px;
}

.about-list {
  list-style: none;
  padding-left: 0;
}

.about-list li {
  font-size: 16px;
  padding: 8px 0;
  color: #333;
  position: relative;
  padding-left: 25px;
}

.about-list li::before {
  content: "";
  color: #d35400;
  font-size: 18px;
  position: absolute;
  left: 0;
  top: 5px;
}

.about-img {
  border-radius: 10px;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.about-img:hover {
  transform: scale(1.05);
}

/* Responsive */
@media (max-width: 992px) {
  .about-us .row {
    flex-direction: column-reverse;
    text-align: center;
  }

  .about-content {
    padding: 20px;
  }

  .about-img {
    margin-bottom: 20px;
  }
}

.vision-content {
  display: flex;
  align-items: center;
  /* border-radius: 8px; */
  flex-direction: column;
  transition: border 0.4s ease-out;
}

.vision-content:hover {
  border: 1px solid #ff6600;
}

.vision-content > img {
  /* height: 2rem; */
  width: 5rem;
}
