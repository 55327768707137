/* General Styles */
.e-store.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f5f5f5;
}

.signinn {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.container {
  display: flex;
  width: 100%;
  height: 100%;
}

.form-container {
  width: 50%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-img img {
  max-width: 250px;
  margin-bottom: 0px;
}

/* input[type="text"] {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
} */

.signin-btn {
  width: 25%;
  padding: 7px;
  background: #a52444;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.signin-btn:hover {
  /* background: linear-gradient(to right, #ff4b2b, #ff416c); */
  background: #a52444;
}

.overlay-container {
  width: 50%;
  background: #a52444;
  /* background: linear-gradient(to right, #ff416c, #ff4b2b); */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 40px;
}

.overlay-panel h1 {
  font-size: 24px;
  font-weight: bold;
}

.overlay-panel p {
  font-size: 16px;
  margin-top: 10px;
}

.otp-btn {
  background-color: #a52444;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 100px;
  position: relative;
}

.otp-btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.small-loader {
  width: 18px;
  height: 18px;
  border: 2px solid white;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login-page-img {
  width: 20vw;
}

.overlay-container {
  background: white;
}

.e-store.main {
  background: white;
}
